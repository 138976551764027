import React from 'react';
import { ListViewHeader } from '@progress/kendo-react-listview';
import LabelTag from '../../Components/LabelTag/LabelTag';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  isFeatureSubscribed,
  isFeatureExpired,
  isWorldTenant,
  getSubscription,
} from '../../../../Utils/Auth/ValidatePermissions';
import FeatureContentSlider from '../Components/ContentSlider/FeatureContentSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeatureDetail from '../Components/ContentSlider/FeatureDetail';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { convertToLocalDate } from 'smart-react';

/**
 * Header Render
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Header component.
 */
export const HeaderRender = (props) => {
  return (
    <ListViewHeader className='listview-header'>{props.title}</ListViewHeader>
  );
};

/**
 * ListViewItemRender
 * @props {React.FC<Props>}
 * @returns {React.ReactElement} The List View Item Render component.
 */
export const ListViewItemRender = ({
  dataItem,
  subscribeFeature,
  isOrganizationAdmin,
  isOrganizationProduct,
  tenantID,
  subscribed,
  updateData,
  isAdminProduct,
  isAdmin,
}) => {
  let feature = dataItem;
  const tooltip = React.useRef(null);

  const [showModal, setShowModal] = React.useState(false);
  const [selectedFeature, setSelectedFeature] = React.useState(null);
  // Check if we should render the component
  const shouldRender =
    !subscribed || (subscribed && isFeatureSubscribed(feature));

  if (!shouldRender) {
    return null;
  }
  const featurePermission = getSubscription(feature?.Permissions);

  const extendFeature = (feature) => {
    setSelectedFeature(feature);
    setShowModal(true);
  };

  React.useEffect(() => {
    if (!showModal) {
      setSelectedFeature(null);
    }
  }, [showModal]);
  return (
    <>
      <div className='k-listview-item product-listview product-row subscription-feature-item'>
        <div className='subscription-feature-title'>
          <label>
            {feature?.display_name}{' '}
            {feature?.Permissions?.length > 0 &&
              featurePermission?.SubscriptionEndDate &&
              isAdminProduct &&
              isAdmin && (
                <FontAwesomeIcon
                  className='edit-feature-icon'
                  icon={faPenToSquare}
                  onClick={() => extendFeature(feature)}
                />
              )}
            {isFeatureExpired(featurePermission?.SubscriptionEndDate) && (
              <LabelTag className='error' text={'Expired'} />
            )}
            {isFeatureSubscribed(feature) &&
              !isFeatureExpired(featurePermission?.SubscriptionEndDate) && (
                <LabelTag className='success' text={'Subscribed'} />
              )}
          </label>
          {feature?.Permissions?.length > 0 &&
            featurePermission?.SubscriptionEndDate && (
              <span className='expiry-date'>
                Expiration Date:{' '}
                {convertToLocalDate(featurePermission?.SubscriptionEndDate)}
              </span>
            )}

          <small className='text-error'>
            {!feature?.IsEnabled && !isFeatureSubscribed(feature)
              ? 'If you want to try out and you are not allowed to do so, send email to sales@smart-is.com'
              : ''}
          </small>
        </div>
        <div
          className='btn-subscribe-wrapper'
          onMouseOver={(event) => {
            return tooltip.current && tooltip.current.handleMouseOver(event);
          }}
          onMouseOut={(event) => {
            return tooltip.current && tooltip.current.handleMouseOut(event);
          }}
        >
          <button
            className={`subscribe-button k-mx-2`}
            onClick={() => subscribeFeature(feature?.feature_id)}
            disabled={
              !feature?.IsEnabled ||
              isFeatureSubscribed(feature) ||
              isFeatureExpired(featurePermission?.SubscriptionEndDate) ||
              (isOrganizationProduct && !isOrganizationAdmin) ||
              isWorldTenant(tenantID)
            }
            title={
              !feature?.IsEnabled && !isFeatureSubscribed(feature)
                ? 'If you want to try out and you are not allowed to do so, send email to sales@smart-is.com'
                : ''
            }
          >
            {'Subscribe'}
          </button>
        </div>
        <Tooltip
          ref={tooltip}
          anchorElement='target'
          position='left'
          openDelay={300}
        />
      </div>
      <FeatureContentSlider show={showModal}>
        <FeatureDetail
          showModal={showModal}
          setShowModal={setShowModal}
          selectedFeature={selectedFeature}
          updateData={updateData}
          tenantID={tenantID}
        />
      </FeatureContentSlider>
    </>
  );
};
export default ListViewItemRender;
