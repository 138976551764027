import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loader, ErrorFallback } from 'smart-react';
import { Card, CardTitle, CardBody } from '@progress/kendo-react-layout';
import ApplicationKeys from '../../../ApplicationKey/Pages/ApplicationKeys';
import './ApplicationKey.scss';

const ApplicationKey = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <React.Suspense fallback={<Loader />}>
        <Card className='tile-layout-card'>
          <CardTitle>
            <div className='page-title page-button-title'>Application Key</div>
          </CardTitle>
          <CardBody>
            <div className='card-body add-page-section no-grid-scroll-horizontal k-px-4'>
              <ApplicationKeys isOrganization={false} />
            </div>
          </CardBody>
        </Card>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default ApplicationKey;
