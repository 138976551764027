import React from 'react';
import { Form, Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { SliderCard, Loader } from 'smart-react';
import { updateFeature } from '../../../../Products/Services/ProductService';
import {
  buildNotification,
  GenerateNotification,
  isFromValid,
  DatePickerInput,
} from 'smart-react';
import { getSubscription } from '../../../../../Utils/Auth/ValidatePermissions';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../../constants/eventDataTypes';

import {
  ERROR_MESSAGE,
  UPDATE_MESSAGE,
} from '../../../../../constants/notificationMessages';

/**
 * Feature Detail Module
 * @param {*} props props passed to component
 * @returns {JSX.Element} The FeatureDetail view.
 */
const FeatureDetail = ({
  setShowModal,
  selectedFeature,
  updateData,
  selectedProduct,
  tenantID,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    SubscriptionEndDate: '',
  });
  const [validateForm, setValidateForm] = React.useState(false);
  const requiredFields = ['SubscriptionEndDate'];
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  React.useEffect(() => {
    if (selectedFeature) {
      const featurePermission = getSubscription(selectedFeature?.Permissions);
      setFormData(featurePermission);
    }
  }, [selectedFeature]);

  React.useEffect(() => {
    if (selectedProduct) {
      setFormData({
        SubscriptionEndDate: new Date(),
      });
    }
  }, [selectedProduct]);

  /**
   * update all features of product
   * @param response
   */
  const updateProduct = async ({ data }) => {
    try {
      let lastResponse = null;

      // Check if features exist
      if (!selectedProduct?.features || selectedProduct.features.length === 0) {
        return null;
      }

      // Process features sequentially to capture the last response
      for (const feature of selectedProduct.features) {
        let featureData;

        if (feature?.Permissions?.length > 0) {
          let tempData = getSubscription(feature?.Permissions);
          featureData = {
            TenantId: tempData?.TenantId,
            FeatureId: tempData?.FeatureId,
            SubscriptionEndDate:
              formData?.SubscriptionEndDate?.toLocaleString(),
          };
        } else {
          featureData = {
            TenantId: tenantID,
            FeatureId: feature?.feature_id,
            SubscriptionEndDate:
              formData?.SubscriptionEndDate?.toLocaleString(),
          };
        }

        // Update each feature and store the response
        lastResponse = await updateFeature({
          data: featureData,
        });
      }

      // Return the response from the last feature update
      return lastResponse;
    } catch (error) {
      console.error('Error updating feature data:', error);
      throw error;
    }
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async () => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = UPDATE_MESSAGE;
    const data = {
      TenantId: formData?.TenantId,
      FeatureId: formData?.FeatureId,
      SubscriptionEndDate: formData?.SubscriptionEndDate?.toLocaleString(),
    };
    let response;

    try {
      setValidateForm(false);
      if (selectedProduct) {
        response = await updateProduct({
          data,
        });
      } else {
        response = await updateFeature({
          data,
          isServiceWorker: false,
        });
      }

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      } else {
        setShowModal(false);
        updateData();
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Form
        render={(formRenderProps) => (
          <SliderCard
            title={'Update Feature'}
            handleSlide={() => {
              setShowModal(false);
            }}
            className='ai-slider content-slider-form'
          >
            <SliderCard.Body>
              {loading && <Loader />}
              <>
                <div className='card-content content-slider-form'>
                  <div className='single-field-row'>
                    <div className='field-wrapper k-d-flex'>
                      <div
                        className={`field-wrapper k-text-left  ${formData?.SubscriptionEndDate ? '' : 'field-wrapper-placeholder'}`}
                      >
                        <Field
                          key={'SubscriptionEndDate'}
                          id={'SubscriptionEndDate'}
                          name={'SubscriptionEndDate'}
                          label='Expiration Date'
                          format={'MM-dd-yyyy'}
                          data={new Date(formData?.SubscriptionEndDate)}
                          onChange={handleInputChange}
                          component={DatePickerInput}
                          optional={false}
                          min={yesterday}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </SliderCard.Body>
            <SliderCard.Footer>
              <Button
                icon={'cancel'}
                onClick={() => setShowModal(false)}
                type='button'
              >
                Cancel
              </Button>
              <Button themeColor={'primary'} icon='save' onClick={onSave}>
                Submit
              </Button>
            </SliderCard.Footer>
          </SliderCard>
        )}
      />
    </>
  );
};
export default FeatureDetail;
